import SharedLayoutComponent from '../../sharedlayout';
import Link from 'next/link';
import ErrorDTMEvent from '../ErrorDTMEvent/ErrorDTMEvent';
import classes from './_oops-error.module.scss';
import OopsErrorRedirection from './OopsErrorRedirection';
import InjectDynamicYield from '@next-app/script/InjectDynamicYield';

interface ErrorProps {
  error: Error & { digest?: string };
}

const OopsError = ({ error }: ErrorProps) => {
  return (
    <SharedLayoutComponent>
      <InjectDynamicYield page={'OTHER'} data={['oops-page']} />
      <div className="container">
        <div className="row">
          <div className="col">
            <div
              className={`${classes['top-content']}`} //"d-flex justify-content-center"
              data-att="error-page"
            >
              <div className={`${classes['top-row-1']}`}>
                Oops! This Page Is on a Field Trip.
              </div>
              <div className={`${classes['top-row-2']}`}>
                Click the links below to continue shopping.
              </div>
              <OopsErrorRedirection />
            </div>
          </div>
        </div>
        <div className={`row ${classes['chiclets-container']}`}>
          <div className="col">
            <h2 className={`${classes['section-title']}`}>
              Explore Categories
            </h2>
            <div
              className={`row row-cols-2 row-cols-md-6 g-2 ${classes['chiclets']}`}
            >
              <div className="col">
                <div className={`${classes['chiclet-1']}`}>
                  <Link
                    href="/products/language/N/951228441"
                    className="text-white"
                  >
                    Language
                  </Link>
                </div>
              </div>
              <div className="col">
                <div className={`${classes['chiclet-2']}`}>
                  <Link
                    href="/products/math/N/598473326"
                    className="text-white"
                  >
                    Math
                  </Link>
                </div>
              </div>
              <div className="col">
                <div className={`${classes['chiclet-3']}`}>
                  <Link
                    href="/products/blocks-manipulatives/N/1784882785"
                    className={`text-white ${classes['vertical-align']}`}
                  >
                    Blocks & Manipulatives
                  </Link>
                </div>
              </div>
              <div className="col">
                <div className={`${classes['chiclet-4']}`}>
                  <Link
                    href="/products/dramatic-play/N/1282659029"
                    className="text-white"
                  >
                    Dramatic Play
                  </Link>
                </div>
              </div>
              <div className="col">
                <div className={`${classes['chiclet-5']}`}>
                  <Link
                    href="/products/arts-crafts/N/4206337237"
                    className="text-white"
                  >
                    Arts & Crafts
                  </Link>
                </div>
              </div>
              <div className="col">
                <div className={`${classes['chiclet-6']}`}>
                  <Link
                    href="/products/teaching-resources/N/419068704"
                    className={`text-white ${classes['vertical-align']}`}
                  >
                    Teaching Resources
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div id="op_carousel"></div>
          </div>
        </div>
      </div>

      <ErrorDTMEvent />
    </SharedLayoutComponent>
  );
};

export default OopsError;
